import * as React from "react";
import styled from "styled-components/macro";
import { BoldSecondaryText, SmallSecondaryText } from "./common";

const FAQContainer = styled.div`
  width: 320px;
`;

const FAQ = ({ faq }: { faq: { question: string; answer: string } }) => {
  return (
    <FAQContainer>
      <BoldSecondaryText style={{ marginBottom: 12 }}>
        {faq.question}
      </BoldSecondaryText>
      <SmallSecondaryText>{faq.answer}</SmallSecondaryText>
    </FAQContainer>
  );
};

export default FAQ;
