import React from "react";
import { ApiNameType, API_ADDITIONAL_METADATA } from "../utils/apis";
import styled, { CSSProperties } from "styled-components";
import { useState } from "react";
import { SecondaryButton } from "./common";
import { SearchIcon } from "./icons";
import { COLORS } from "../utils/colors";
import { Link } from "gatsby";
import { useKBar } from "kbar";

const SmallSecondaryText = styled.div`
  color: ${COLORS.darkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
`;

const BoldSecondaryText = styled.div`
  color: ${COLORS.lightGray};
  font-weight: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

const RelatedApiContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
`;

const APICta = ({
  api,
  style = {},
}: {
  api?: ApiNameType;
  style: CSSProperties;
}) => {
  const { query } = useKBar();

  const onBrowseAllApis = () => {
    query.setCurrentRootAction("allAPIs");
    query.toggle();
  };

  if (!api) {
    return (
      <RelatedApiContainer>
        <div style={{ marginRight: 16 }}>
          <SearchIcon color={COLORS.lightPurple} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BoldSecondaryText style={{ marginBottom: 12 }}>
            All APIs
          </BoldSecondaryText>
          <SmallSecondaryText style={{ height: 30 }}>
            Browse all the APIs available on tryapis.com
          </SmallSecondaryText>
          <SecondaryButton
            style={{ marginTop: 12, width: 60 }}
            onClick={onBrowseAllApis}
          >
            Browse
          </SecondaryButton>
        </div>
      </RelatedApiContainer>
    );
  }
  if (!API_ADDITIONAL_METADATA[api]) {
    return <></>;
  }

  const icon = API_ADDITIONAL_METADATA[api].icon;
  const name = API_ADDITIONAL_METADATA[api].prettyName;
  const shortDescription = `${name}'s official REST API`;
  return (
    <RelatedApiContainer style={style}>
      <div style={{ marginRight: 16 }}>{icon}</div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BoldSecondaryText style={{ marginBottom: 12 }}>
          {name}
        </BoldSecondaryText>
        <SmallSecondaryText style={{ height: 30 }}>
          {shortDescription}
        </SmallSecondaryText>
        <Link to={`/${api}`}>
          <SecondaryButton style={{ marginTop: 12, width: 45 }}>
            View
          </SecondaryButton>
        </Link>
      </div>
    </RelatedApiContainer>
  );
};

export default APICta;
