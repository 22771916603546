import * as React from "react";

import styled from "styled-components";
import EndpointsNavigation from "./EndpointsNavigation";

import {
  EndpointSummaryType,
  makeCommaSeparatedString,
  truncate,
} from "../utils/utils";
import Layout from "../components/Layout";
import {
  ApiMetadataType,
  ApiNameType,
  API_ADDITIONAL_METADATA,
} from "../utils/apis";
import {
  generateUrl,
  MainContent,
  SmallSecondaryText,
  TitleNearWhite,
} from "../components/common";
import { Link } from "gatsby";
import { COLORS } from "../utils/colors";
import { Book, List, Protection, RightArrow } from "../components/icons";
import MethodTag from "../components/MethodTag";
import { sizes } from "../utils/sizes";
import APICta from "../components/APICta";
import { PROXY_URL } from "../utils/constants";
import { uniq } from "lodash";
import FAQ from "../components/Faq";

type Props = {
  apiName: ApiNameType;
  apiMetadata: ApiMetadataType;
  apiEndpointsSummary: EndpointSummaryType[];
};

const SecondaryText = styled.div`
  color: ${COLORS.darkGray};
  font-weight: 400;
  font-size: 13px;
`;
const SecondaryTextH1 = styled.h1`
  color: ${COLORS.nearWhite};
  margin-bottom: 4px;
  margin-top: 2px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const Separator = styled.hr`
  background-color: ${COLORS.brightDarkGray};
  width: 100%;
  border: none;
  height: 1px;
  margin-top: 44px;
`;

const PopularEndpointContainer = styled.div`
  background: ${COLORS.slightStandoutBoxGray};
  border: 1px solid ${COLORS.brightDarkGray};
  align-items: center;
  width: 320px;

  .normal {
    display: flex;
  }

  .onhover {
    display: none;
  }

  &:hover {
    border: 1px solid ${COLORS.onHoverPurple};

    .onhover {
      display: flex;
    }
    .normal {
      display: none;
    }
  }

  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  height: 88px;
`;

const PopularEndpoint = ({
  apiEndpointsSummary,
  operationId,
}: {
  apiEndpointsSummary: EndpointSummaryType[];
  operationId: string;
}) => {
  const endpoint = apiEndpointsSummary.find(
    (endpoint) => endpoint.operationId === operationId
  ) as EndpointSummaryType;

  if (!endpoint) {
    return <></>;
  }

  return (
    <PopularEndpointContainer>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <MethodTag size="small" method={endpoint.method} />
          <SecondaryText
            style={{
              color: COLORS.nearWhite,
              marginLeft: 12,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: 220,
            }}
          >
            {generateUrl(endpoint.path)}
          </SecondaryText>
        </div>
        <SmallSecondaryText style={{ marginTop: 12, maxWidth: 284 }}>
          {truncate(endpoint.summary, 85)}
        </SmallSecondaryText>
      </div>
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <RightArrow />
      </div>
    </PopularEndpointContainer>
  );
};

const LinksContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${sizes.mediumLarge}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.small}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const RelatedAPIsContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${sizes.mediumLarge}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.small}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const QuickLinkContainer = styled.div`
  align-items: center;
  border: 1px solid ${COLORS.brightDarkGray};
  width: 320px;

  .normal {
    display: flex;
  }

  .onhover {
    display: none;
  }

  &:hover {
    border: 1px solid ${COLORS.onHoverPurple};

    .onhover {
      display: flex;
    }
    .normal {
      display: none;
    }
  }

  border-radius: 6px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
`;

const QuickLink = ({
  text,
  icon,
  newTab,
  link,
}: {
  text: string;
  icon: JSX.Element;
  link: string;
  newTab?: boolean;
}) => {
  return (
    <Link to={link} target={newTab ? "_blank" : undefined}>
      <QuickLinkContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <SecondaryText style={{ color: COLORS.nearWhite, marginLeft: 12 }}>
            {text}
          </SecondaryText>
        </div>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <RightArrow />
        </div>
      </QuickLinkContainer>
    </Link>
  );
};

const IndexPage = ({ pageContext }: { pageContext: Props }) => {
  const { apiName, apiMetadata, apiEndpointsSummary } = pageContext;
  const popularOperationIds =
    API_ADDITIONAL_METADATA[apiName].popularOperationIds;

  const faqs = API_ADDITIONAL_METADATA[apiName].faqs(
    apiMetadata,
    apiEndpointsSummary
  );
  const relatedAPIs = API_ADDITIONAL_METADATA[apiName].relatedAPIs;
  const prettyName = API_ADDITIONAL_METADATA[apiName].prettyName;

  const methods = uniq(apiEndpointsSummary.map((e) => e.method))
    .map((e) => e.toUpperCase())
    .sort();

  return (
    <div>
      <Layout
        apiMetadata={apiMetadata}
        apiEndpointsSummary={apiEndpointsSummary}
        apiName={apiName}
        seoTitle={`Easily explore ${prettyName}'s REST API`}
        seoDescription={`tryapis.com lets you easily send requests to ${prettyName}'s ${
          apiEndpointsSummary.length
        } ${makeCommaSeparatedString(
          methods
        )} API endpoints from your browser or from a cURL command. Headers, query and body parameters are pre-filled with in-line docs to save you time.`}
      >
        <EndpointsNavigation
          apiName={apiName}
          tags={apiMetadata.tags || []}
          endpointsSummary={apiEndpointsSummary}
        >
          <MainContent>
            <div>{API_ADDITIONAL_METADATA[apiName].logo}</div>
            <SecondaryTextH1 style={{ marginTop: 12 }}>
              Easily explore {prettyName}'s REST API
            </SecondaryTextH1>
            <SecondaryText
              style={{ marginTop: 8, fontWeight: 500, maxWidth: 600 }}
            >
              {API_ADDITIONAL_METADATA[apiName].longDescription}
            </SecondaryText>
            <Separator />
            <TitleNearWhite style={{ marginTop: 44, marginBottom: 34 }}>
              Popular endpoints
            </TitleNearWhite>
            <LinksContainer>
              {popularOperationIds.map((operationId) => (
                <Link to={`/${apiName}/api/${operationId}`} key={operationId}>
                  <PopularEndpoint
                    operationId={operationId}
                    apiEndpointsSummary={apiEndpointsSummary}
                  />
                </Link>
              ))}
            </LinksContainer>
            <Separator />
            <TitleNearWhite style={{ marginTop: 44, marginBottom: 34 }}>
              Common questions
            </TitleNearWhite>
            <LinksContainer>
              {faqs.map((faq) => (
                <FAQ faq={faq} key={faq.question} />
              ))}
            </LinksContainer>
            <Separator />
            <TitleNearWhite style={{ marginTop: 44, marginBottom: 34 }}>
              Quick links
            </TitleNearWhite>
            <LinksContainer>
              <QuickLink
                icon={<Book />}
                text="Official documentation"
                link={API_ADDITIONAL_METADATA[apiName].docsUrl}
                newTab
              />
              <QuickLink
                icon={<List />}
                text="Open API (Swagger) Spec"
                link={`${PROXY_URL}/spec/${apiName}`}
                newTab
              />
              {apiMetadata.info.termsOfService && (
                <QuickLink
                  icon={<Protection />}
                  text="API Terms of service"
                  link={apiMetadata.info.termsOfService}
                  newTab
                />
              )}
            </LinksContainer>

            <Separator />
            <TitleNearWhite style={{ marginTop: 44, marginBottom: 34 }}>
              Related APIs
            </TitleNearWhite>
            <RelatedAPIsContainer style={{ marginBottom: 64 }}>
              {relatedAPIs.map((api) => (
                <APICta api={api} key={api} />
              ))}
              <APICta />
            </RelatedAPIsContainer>
          </MainContent>
        </EndpointsNavigation>
      </Layout>
    </div>
  );
};

export default IndexPage;
