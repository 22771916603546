import * as React from "react";
import { useMemo } from "react";
import { useEffect } from "react";

import "../app/antd.css";
import { navigate } from "gatsby";

import { OpenAPIV3 } from "openapi-types";
import { EndpointSummaryType } from "../utils/utils";
import { COLORS } from "../utils/colors";
import styled from "styled-components";
import MethodTag from "../components/MethodTag";
import { Link } from "gatsby";
import { MediumTitle, SmallTitle } from "../components/common";
import { ApiNameType, API_ADDITIONAL_METADATA } from "../utils/apis";

import { SearchIcon } from "../components/icons";
import { useKBar } from "kbar";
import { useHotkeys } from "react-hotkeys-hook";

import { sizes } from "../utils/sizes";

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${sizes.medium}px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
`;

const MobileSearch = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideMenuParentContainer = styled.div`
  min-width: 270px;
  height: 100%;

  @media (max-width: ${sizes.medium}px) {
    display: none;
  }
`;

const SideMenuContainer = styled.div`
  height: 100vh;
  padding-top: 16px;
  position: fixed !important;
  padding-left: 16px;
  min-width: 270px;
  display: flex;
  flex-direction: column;
  background: ${COLORS.backgroundDarkGray};

  border-right: 1px solid ${COLORS.brightDarkGray};
`;

const TopMenuContainer = styled.div`
  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  height: 50px;
  background: ${COLORS.backgroundDarkGray};

  border-bottom: 1px solid ${COLORS.brightDarkGray};

  @media (min-width: ${sizes.medium}px) {
    display: none;
  }
`;

const EndpointTitle = styled.div`
  text-overflow: ellipsis;
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
`;

const ClosedIcon = () => (
  <>
    <svg
      width="12"
      height="12"
      className="normal"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.5L6 7.5L9 4.5"
        stroke={COLORS.darkGray}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      className="onhover"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.5L6 7.5L9 4.5"
        stroke={COLORS.primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

const OpenIcon = () => (
  <>
    <svg
      className="normal"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7.5L6 4.5L3 7.5"
        stroke={COLORS.lightGray}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      className="onhover"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7.5L6 4.5L3 7.5"
        stroke={COLORS.primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

const MenuItem = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 0px;

  font-size: 13px;

  cursor: pointer;
  color: ${(props) =>
    props.isOpen ? `${COLORS.nearWhite}` : `${COLORS.lightGray}`};

  .normal {
    display: flex;
  }

  .onhover {
    display: none;
  }

  &:hover {
    color: ${COLORS.primary};

    .onhover {
      display: flex;
    }
    .normal {
      display: none;
    }
  }
`;

const MobileSelectContainer = styled.select`
  background: ${COLORS.backgroundDarkGray};
  color: ${COLORS.darkGray};
  border: none;
  max-width: 200px;
`;

const MobileSelect = ({
  keys,
  values,
  apiName,
  selectedOperationId,
}: {
  keys: string[];
  values: {
    [tag: string]: EndpointSummaryType[];
  };
  apiName: ApiNameType;
  selectedOperationId?: string;
}) => {
  const onChange = (e: { target: { value: string } }) => {
    const operationId = e.target.value;

    const url = `/${apiName}/api/${decodeURIComponent(operationId || "")}`;

    navigate(url);
  };
  return (
    <MobileSelectContainer
      name={selectedOperationId}
      id={selectedOperationId}
      onChange={onChange}
    >
      {keys.map((k) => (
        <optgroup label={k} key={k}>
          {values[k].map((v) => (
            <option key={v.operationId} value={v.operationId}>
              {v.summary}
            </option>
          ))}
        </optgroup>
      ))}
    </MobileSelectContainer>
  );
};

const SubMenuItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  font-size: 13px;
  color: ${COLORS.darkGray};
  margin: 2px 8px 2px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  ${(props) =>
    props.isSelected &&
    `background-color: ${COLORS.brightDarkGray};
  
  border-radius: 4px;
  
  `}

  &:hover {
    background-color: ${COLORS.brightDarkGray};
    border-radius: 4px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.brightDarkGray};
  border: 1px solid ${COLORS.brightDarkGrayBorder};
  padding: 2px 6px;
  margin-right: 8px;
  border-radius: 8px;
  color: ${COLORS.darkGray};
  cursor: pointer;
  &:hover {
    background: ${COLORS.slightlyBrighterBackgroundDarkGray};
  }
`;

const ScrollContainer = styled.div`
  overflow: auto;
  margin-bottom: 24px;
`;

const EndpointsContainer = ({
  apiName,
  keys,
  tags,
  values,
  selectedOperationId,
  selectedOperationTag,
}: {
  selectedOperationTag: string | undefined;
  apiName: ApiNameType;
  keys: string[];
  values: {
    [tag: string]: EndpointSummaryType[];
  };
  tags: OpenAPIV3.TagObject[];
  selectedOperationId?: string;
}) => {
  useEffect(() => {
    const isInViewport = (offset = 0) => {
      if (!selectedSubMenuItem?.current) return false;
      const top = selectedSubMenuItem?.current?.getBoundingClientRect().top;
      const isBrowser = () => typeof window !== "undefined";
      return (
        top + offset >= 0 &&
        top - offset <= (isBrowser() ? window.innerHeight : 800)
      );
    };

    if (!isInViewport()) {
      selectedSubMenuItem?.current?.scrollIntoView();
    }
  }, []);

  const selectedSubMenuItem = React.useRef<any>(null);

  const [openKey, setOpenKey] = React.useState<string | undefined>(
    selectedOperationTag
      ? selectedOperationTag
      : values[tags[0].name]?.length < MAX_ENDPOINTS_TO_AUTO_OPEN
      ? tags[0].name
      : undefined
  );

  return (
    <ScrollContainer>
      <SmallTitle style={{ marginBottom: 0, marginTop: 12 }}>
        {API_ADDITIONAL_METADATA[apiName].prettyName} API Endpoints
      </SmallTitle>
      {keys.map((key) => {
        const isOpenKey = openKey === key;
        return (
          <div key={key}>
            <MenuItem
              isOpen={isOpenKey}
              onClick={() => {
                setOpenKey(isOpenKey ? undefined : key);
              }}
            >
              <div>{key}</div>
              {isOpenKey ? <OpenIcon /> : <ClosedIcon />}
            </MenuItem>

            {isOpenKey && (
              <div style={{ marginBottom: 8 }}>
                {values[key].map((endpoint) => {
                  const isSelected =
                    endpoint.operationId === selectedOperationId;

                  return (
                    <Link
                      key={endpoint.operationId}
                      to={`/${apiName}/api/${decodeURIComponent(
                        endpoint.operationId || ""
                      )}`}
                    >
                      {isSelected && (
                        // So we scroll to a slight offset on page load
                        <div
                          ref={selectedSubMenuItem}
                          style={{ position: "relative", top: -100, left: 0 }}
                        ></div>
                      )}
                      <SubMenuItem
                        key={endpoint.operationId}
                        isSelected={isSelected}
                      >
                        <EndpointTitle>{endpoint.summary}</EndpointTitle>
                        <MethodTag method={endpoint.method} size="small" />
                      </SubMenuItem>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </ScrollContainer>
  );
};

const MAX_ENDPOINTS_TO_AUTO_OPEN = 10;
const EndpointsNavigation = ({
  children,
  tags,
  apiName,
  selectedOperationId,
  selectedOperationTag,
  endpointsSummary,
}: {
  children: React.ReactNode;
  tags: OpenAPIV3.TagObject[];
  apiName: ApiNameType;
  selectedOperationId?: string;
  selectedOperationTag?: string;
  endpointsSummary: EndpointSummaryType[];
}) => {
  // Perf TODO - move this to gatsby-node
  const endpointByTag = useMemo(() => {
    return endpointsSummary.reduce(
      (
        acc: { [tag: string]: EndpointSummaryType[] },
        endpoint: EndpointSummaryType
      ) => {
        if (acc[endpoint.tag]) {
          acc[endpoint.tag].push(endpoint);
        } else {
          acc[endpoint.tag] = [endpoint];
        }

        return acc;
      },
      {}
    );
  }, [endpointsSummary]);

  const { query } = useKBar();

  const keys: string[] = tags
    .filter((t) => !!endpointByTag[t.name])
    .map((t) => t.name);

  const onSearchClick = () => {
    query.toggle();
  };

  return (
    <Container>
      <SideMenuParentContainer>
        <SideMenuContainer>
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginBottom: 24,
            }}
            to="/"
          >
            <div
              style={{
                borderRadius: 3,
                background: COLORS.primary,
                padding: "3px 6px",
                paddingBottom: 4,
                fontSize: 13,
                color: COLORS.nearWhite,
                marginRight: 8,
                display: "flex",
                fontWeight: 500,
                justifyContent: "center",
              }}
            >
              API
            </div>
            <MediumTitle>tryapis.com</MediumTitle>
          </Link>
          <SearchContainer onClick={onSearchClick}>
            <SearchIcon />
            <div
              style={{
                width: "100%",
                marginLeft: 8,
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Find anything
            </div>
            <div
              style={{
                borderRadius: 4,
                background: COLORS.slightlyBrighterBackgroundDarkGray,
                padding: "2px 8px",
                whiteSpace: "nowrap",
                fontSize: 11,
              }}
            >
              ⌘ K
            </div>
          </SearchContainer>
          <EndpointsContainer
            keys={keys}
            values={endpointByTag}
            apiName={apiName}
            selectedOperationId={selectedOperationId}
            selectedOperationTag={selectedOperationTag}
            tags={tags}
          ></EndpointsContainer>
        </SideMenuContainer>
      </SideMenuParentContainer>

      <TopMenuContainer>
        <MobileSelect
          keys={keys}
          values={endpointByTag}
          apiName={apiName}
          selectedOperationId={selectedOperationId}
        />
        <MobileSearch onClick={onSearchClick}>
          <SearchIcon />
        </MobileSearch>
      </TopMenuContainer>

      {children}
    </Container>
  );
};

export default EndpointsNavigation;
